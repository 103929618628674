<template>
  <div class="help-main" :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-13'">
    <v-row class="">
      <v-col cols="12" lg="10" md="6">
        <h2
          class="mb-4"
          :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
        >
          How to retrieve chatbot URL?
        </h2>

        <div class="mb-5">
          <v-btn
            color="primary"
            @click.stop="goTo('/dashboard/help/chatbot-url')"
            style="color: #fff !important"
            ><v-icon>mdi-arrow-right</v-icon>CLICK HERE</v-btn
          >
        </div>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col cols="12" lg="10" md="6">
        <h2
          class="mb-4"
          :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
        >
          How to embed your Chatbot on your site?
        </h2>

        <div class="mb-5">
          <v-btn
            color="primary"
            @click.stop="goTo('/dashboard/help/embed-url')"
            style="color: #fff !important"
            ><v-icon>mdi-arrow-right</v-icon>CLICK HERE</v-btn
          >
        </div>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col cols="12" lg="10" md="6">
        <h2
          class="mb-4"
          :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
        >
          How to connect with your Messenger?
        </h2>

        <div class="mb-5">
          <v-btn
            color="primary"
            @click.stop="goTo('/dashboard/help/messenger')"
            style="color: #fff !important"
            ><v-icon>mdi-arrow-right</v-icon>CLICK HERE</v-btn
          >
        </div>
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {},

  methods: {
    goTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped></style>
